import React, { ReactElement, useEffect, useRef, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { AdvancedTableWrapper } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Menu from "@civicplus/preamble-ui/lib/Menu";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { bottle } from "../../../provider/Bottle";
import { HttpHelper } from "@civicplus/preamble-ui/lib/Utilities/HttpHelper";
import { ImportSubscribers } from "../../../components/Modals/ImportSubscribers";
import { OrganizationSettingsService } from "../../../services/OrganizationSettingsService";
import { ProductsWithNotifications, ProductTypeLabels } from "../../../entities/ProductType";
import { searchUsers } from "../../../util/UserSearch";
import { SubscribersService } from "../../../services/SubscribersService";
import { SubscriptionList } from "../../../entities/SubscriptionList";
import { SubscriptionListsService } from "../../../services/SubscriptionListsService";
import { TableData, TableState } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";
import { ProductTypeDto, ProductTypeSelector } from "../shared/ProductTypeSelector";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { usePreviousErrors } from "../../../util/PreviousErrors";
import {
    buildCustomSelectPickerFilter,
    buildDateFilter,
    buildUserFilter
} from "@civicplus/preamble-ui/lib/Utilities/odataHelper";
import {
    ByUser,
    LastModified,
    makeDateRangeFilter,
    makeUsersFilter
} from "@civicplus/preamble-ui/lib/Utilities/CommonTableComponents";
import { debounce } from "../../../util/Debounce";
import { buildUserFilterFromOptionShape } from "../../../util/FilterHelper";

const ViewSubscriptionLists: React.FC = () => {
    const subscribersService: SubscribersService = bottle.container.SubscribersService;
    const subscriptionListsService: SubscriptionListsService = bottle.container.SubscriptionListsService;
    const settingsService: OrganizationSettingsService = bottle.container.OrganizationSettingsService;
    const orgService: any = bottle.container.OrgService;

    const refContainer: any = useRef<typeof AdvancedTableWrapper | null>(null);
    const orgId: string = orgService.orgId;

    const shouldImport = HttpHelper.getQuery("import");

    const [showImportModal, setShowImportModal] = useState<boolean>(shouldImport != null && Boolean(shouldImport));
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [currentSubscriptionList, setCurrentSubscriptionList] = useState<{
        data: SubscriptionList | null;
        index: number;
    }>();

    const [error, setError] = useState<string | undefined>(undefined);
    const [selectedListId, setSelectedListId] = useState<number | null>(null);
    const prevError = usePreviousErrors(error);
    const history = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const getRows = async (state: TableState): Promise<TableData> => {
        const lists = await subscriptionListsService.getSubscriptionLists(
            state.page,
            state.rowsPerPage,
            false,
            state.sortOrder.name || "name",
            state.sortOrder.direction || "asc",
            {
                ...buildCustomSelectPickerFilter(
                    state.filterList[2]?.map((x: any) => {
                        return x.name;
                    }) ?? [],
                    "subscriptionListProductType",
                    true,
                    "productType"
                ),
                ...buildDateFilter(state.filterList[3], "lastModified"),
                ...buildUserFilterFromOptionShape(state.filterList[4], "lastModifiedBy")
            }
        );
        const data = lists.items.map((list, i) => {
            return buildRow(list, i);
        });

        return { count: lists.count, data };
    };

    const onRowClick = (
        data: string[],
        meta: {
            dataIndex: number;
            rowIndex: number;
        },
        event: any
    ) => {
        const listId = data[0];
        history(`/${orgId}/admin/lists/${listId}/edit`);
    };

    const buildRow = (list: SubscriptionList, index: number) => {
        return [
            list.id,
            list.name,
            buildProductList(list),
            <LastModified
                key={`subList-${list.id}`}
                date={list.lastModified!}
                organizationTimeZone={settingsService.settings.defaultListSendTimeZoneLabel}
            />,
            <ByUser key={`byUser-${list.lastModifiedBy?.id}}`} users={new Map()} entity={list.lastModifiedBy!} />,
            buildActionMenu(list, index)
        ];
    };

    const navigateToCreateList = () => {
        history(`/${orgId}/admin/lists/create`);
    };

    const importExportModal = () => {
        history({ search: "" });
        setShowImportModal(false);
    };

    const getSubscriberExportFile = async (list: SubscriptionList) => {
        await subscribersService.getSubscribersExport(list.id!, list.name);
    };

    const buildHcmsCategoryIds = (list: SubscriptionList) => {
        return <Typography variant="body1">{list.hcmsCategoryCount}</Typography>;
    };

    const buildProductList = (list: SubscriptionList) => {
        if (list.productTypeList) {
            return (
                <Typography variant="body1">
                    {list.productTypeList
                        .sort((a, b) => ProductTypeLabels[a].localeCompare(ProductTypeLabels[b]))
                        .map((x) => ProductTypeLabels[x])
                        .join(", ")}
                </Typography>
            );
        }
    };

    const deleteList = async (id: any) => {
        const tableRef = refContainer && (refContainer.current as typeof AdvancedTableWrapper);
        const filter = (item: any) => {
            return item[0] === id;
        };

        if (tableRef) {
            await tableRef.loadRows();
            tableRef.setLoading(true);
            await subscriptionListsService.deleteSubscriptionList(id!);
            await tableRef.deleteRowByFilter(filter, async () => {}, "Item successfully removed");
            tableRef.setLoading(false);
        }
        setShowDeleteModal(false);
    };

    const buildActionMenu = (list: SubscriptionList, index: number) => {
        return (
            <Menu
                id={`${list.id}-subscriptionList-actions`}
                data-testid={`${list.id}-subscriptionList-actions`}
                type="action"
                title={`Actions for list-${list.name}`}
                stopPropagation={true}
                items={[
                    {
                        display: "Modify",
                        action: () => {
                            history(`/${orgId}/admin/lists/${list.id}/edit`, { state: { list: list } });
                        }
                    },
                    {
                        display: "Subscribers",
                        action: () => {
                            history(`/${orgId}/admin/lists/${list.id}/subscribers`, { state: { list: list } });
                        }
                    },
                    {
                        display: "Import Subscribers",
                        action: () => {
                            setShowImportModal(true);
                            setSelectedListId(list.id!);

                            history({
                                search: `?import=true&listId=${list.id}`
                            });
                        }
                    },

                    {
                        display: "Export Subscribers",
                        action: async () => {
                            if (list.id) {
                                const tableRef = refContainer && (refContainer.current as typeof AdvancedTableWrapper);
                                tableRef!.setRowLoading(index);
                                await getSubscriberExportFile(list);
                                tableRef!.reloadRow(index, buildRow(list, index));
                            }
                        }
                    },
                    {
                        display: "Message History",
                        action: () => {
                            history(`/${orgId}/admin/lists/${list.id}/messages/history`, { state: { list: list } });
                        }
                    },
                    {
                        display: "Pending Messages",
                        action: () => {
                            history(`/${orgId}/admin/lists/${list.id}/messages/pending`, { state: { list: list } });
                        }
                    },
                    {
                        display: "Delete",
                        action: async () => {
                            setShowDeleteModal(true);
                            setCurrentSubscriptionList({ data: list, index });
                        }
                    }
                ]}
            />
        );
    };

    const buildBody = () => {
        const timeZone = settingsService.settings.defaultListSendTimeZoneLabel;
        const orgApps = orgService.organization?.applications?.map((x: any) => x.productType);
        const includedApps = orgApps ? ProductsWithNotifications.filter((p) => orgApps?.includes(p)) : [];

        const columns = [
            {
                name: "id",
                options: {
                    sort: false,
                    filter: false,
                    display: "false"
                }
            },
            { name: "name", label: "Name", options: { filter: false, sort: true } },
            {
                name: "Product",
                options: {
                    sort: false,
                    customFilterListOptions: { render: (v: any) => v.map((x: any) => x.label) },
                    filter: true,
                    filterType: "custom",
                    filterOptions: {
                        display: function productCustomFilter(
                            filterList: any,
                            onChange: any,
                            index: number,
                            column: any
                        ): ReactElement {
                            return (
                                <ProductTypeSelector
                                    includedApps={includedApps}
                                    isInDialog={true}
                                    label="Product"
                                    onChange={function (productType: ProductTypeDto[]): void {
                                        onChange(
                                            productType.map((x) => {
                                                return { label: x.friendlyName, name: x.name, value: x.value };
                                            }),
                                            index,
                                            column
                                        );
                                    }}
                                    placeholder="Select or type filter..."
                                    value={filterList[index].map((x: any) => x.value)}
                                />
                            );
                        }
                    }
                }
            },
            {
                name: "lastModified",
                label: "Updated",
                options: {
                    ...makeDateRangeFilter("Updated", timeZone, true),
                    filter: true,
                    sort: true
                }
            },
            {
                name: "lastModifiedBy",
                label: "By",
                options: {
                    ...makeUsersFilter("User", debounce(searchUsers, 300), null, true),
                    filter: true,
                    sort: false
                }
            },
            AdvancedTableWrapper.defaultActionMenuColumn()
        ];

        return (
            <>
                {showImportModal && (
                    <ImportSubscribers
                        orgId={orgId}
                        selectedListId={selectedListId as number}
                        onClose={importExportModal}
                    />
                )}

                <AdvancedTableWrapper
                    columns={columns}
                    rows={getRows}
                    scrollToTop={true}
                    ref={refContainer}
                    emptyMessage="There are currently no subscription lists. Set up your first list to get started!"
                    initialSortDirection="asc"
                    initialSortColumn="name"
                    initialSortColumnIndex={1}
                    showFilter={true}
                    rowsPerPage={25}
                    rowsPerPageOptions={[25, 50, 100]}
                    onRowClick={onRowClick}
                    download={false}
                    enablePreload={true}
                    serverSide={true}
                    showSearch={false}
                    closeSnackbar={() => {}}
                    enqueueSnackbar={() => "message"}
                />

                {showDeleteModal && (
                    <Dialog
                        onClose={() => {
                            setShowDeleteModal(false);
                        }}
                        open={showDeleteModal}
                        actions={[
                            <Button
                                color="primary"
                                onClick={() => deleteList(currentSubscriptionList!.data!.id)}
                                key="ok"
                            >
                                Ok
                            </Button>,
                            <Button
                                onClick={() => {
                                    setShowDeleteModal(false);
                                }}
                                key="cancel"
                            >
                                Cancel
                            </Button>
                        ]}
                        title="Delete"
                    >
                        <Typography>Are you sure you want to delete this subscription list?</Typography>
                    </Dialog>
                )}
            </>
        );
    };

    useEffect(() => {
        if (prevError && error && error !== prevError) {
            enqueueSnackbar(error, { variant: "error" });
        }
    }, [enqueueSnackbar, error, prevError]);

    return (
        <>
            <Titlebar
                id="titlebar"
                title="Subscription Lists"
                buttons={
                    !error
                        ? [
                              <Button
                                  key="newbutton"
                                  id="newbutton"
                                  color="primary"
                                  onClick={navigateToCreateList}
                                  startIcon={<AddIcon />}
                              >
                                  New Subscription List
                              </Button>
                          ]
                        : undefined
                }
            />

            {buildBody()}
        </>
    );
};

export default ViewSubscriptionLists;
