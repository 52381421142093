import Bottle from "bottlejs";
import { isInRole } from "@civicplus/preamble-ui/lib/Utilities/User";
import OrgService from "@civicplus/preamble-ui/lib/Services/OrgService/OrgService";
import { ApiService } from "../services/ApiService";
import { AuthService } from "../services/AuthService";
import { HcmsService } from "../services/HcmsService";
import { HistoryLogService } from "../services/HistoryLogService";
import { ManageSubscriptionsService } from "../services/ManageSubscriptionsService";
import { MessageService } from "../services/MessageService";
import { OrganizationService } from "../services/OrganizationService";
import { OrganizationSettingsService } from "../services/OrganizationSettingsService";
import { PendingMessageService } from "../services/PendingMessageService";
import { PermissionsService } from "../services/PermissionsService";
import { SubscriberImportService } from "../services/SubscriberImportService";
import { SubscribersService } from "../services/SubscribersService";
import { SubscriptionListsService } from "../services/SubscriptionListsService";
import { TemplateService } from "../services/TemplateService";
import { UserManager } from "../services/UserManager";
import { AxiosHttpProvider } from "./http/AxiosHttpProvider";
import { CacheProvider } from "./cache/cacheProvider";
import { WindowRouter } from "../shell/WindowRouter";
import { PrivacyService } from "../services/PrivacyService";
import { SuppressionsService } from "../services/SuppressionsService";
import { Organization, OrganizationDto } from "../entities/Organization";
import { UnsubscribeService } from "../services/UnsubscribeService";
import { ProductType } from "../entities/ProductType";

export let bottle: Bottle;

const init = () => {
    bottle = new Bottle();
    bottle.service("ApiService", ApiService, "HttpProvider", "WindowRouter");
    bottle.service("AuthService", AuthService, "ApiService");
    bottle.service("CacheProvider", CacheProvider);
    bottle.service("HcmsService", HcmsService, "ApiService");
    bottle.service("HistoryLogService", HistoryLogService, "ApiService");
    bottle.service("HttpProvider", AxiosHttpProvider);
    bottle.service("ManageSubscriptionsService", ManageSubscriptionsService);
    bottle.service("MessageService", MessageService, "ApiService");
    bottle.service("OrganizationService", OrganizationService, "ApiService", "CacheProvider");
    bottle.service("OrganizationSettingsService", OrganizationSettingsService, "ApiService");
    bottle.service("PendingMessageService", PendingMessageService, "ApiService");
    bottle.service("PermissionsService", PermissionsService, "ApiService");
    bottle.service("PrivacyService", PrivacyService, "ApiService");
    bottle.service("SubscriberImportService", SubscriberImportService, "ApiService", "UserManager");
    bottle.service("SubscribersService", SubscribersService, "ApiService");
    bottle.service("SubscriptionListsService", SubscriptionListsService, "ApiService");
    bottle.service("SuppressionsService", SuppressionsService, "ApiService");
    bottle.service("TemplateService", TemplateService, "ApiService");
    bottle.service("UnsubscribeService", UnsubscribeService, "ApiService");
    bottle.service("UserManager", UserManager, "AuthService", "WindowRouter");
    bottle.service("WindowRouter", WindowRouter);
};

export const setupOrganizationService = async (orgIdOrName: string | null) => {
    if (!bottle.container.OrgService && orgIdOrName !== null) {
        let user: any = null;
        let orgDto: OrganizationDto | null = null;
        let orgService: any = null;
        bottle.container.ApiService.orgId = orgIdOrName;

        const userManager: UserManager = bottle.container.UserManager;

        const isAuthenticated = await userManager.isAuthenticatedAsync(orgIdOrName);

        if (isAuthenticated) {
            user = await userManager.getUserAsync();

            const getOrgFunc = async (
                _page: number,
                _pageSize: number,
                _select: number,
                _order: string,
                search = ""
            ): Promise<{ items: Organization[]; total: number }> => {
                const service: OrganizationService = bottle.container.OrganizationService;
                const isSuperUser = await userManager.isSuperUser();

                if (search === "Select an Organization") {
                    search = "";
                }

                if (isSuperUser) {
                    const orgs = await service.searchOrgs(search);
                    if (!orgs) {
                        return {
                            items: [],
                            total: 0
                        };
                    }

                    return {
                        items: orgs.items,
                        total: orgs.items.length
                    };
                }

                const orgs = await service.getAssociatedOrgs(search);

                if (!orgs) {
                    return {
                        items: [],
                        total: 0
                    };
                }
                return {
                    items: orgs,
                    total: orgs.length
                };
            };

            const isInRoleFunc = (role: string): boolean => {
                return isInRole(role, user);
            };

            orgService = new OrgService({
                endpoint: userManager.getIdentityServerBaseUrl(),
                accessToken: user.access_token,
                idToken: user.id_token
            });
            orgService.GetUsersOrganizations = getOrgFunc;
            orgService.IsInRole = isInRoleFunc;
        }

        if (orgIdOrName) {
            const organizationService = bottle.container.OrganizationService as OrganizationService;
            orgDto = await organizationService.getOrganization();
            localStorage.setItem("organization:Notifications", orgIdOrName);
        }

        bottle.service("OrgService", function () {
            if (orgDto?.organization) {
                orgDto.organization.hasHcmsApp = orgDto?.organization.hcmsApp !== null;
                orgDto.organization.hasNotificationApp = orgDto?.organization.applications?.some(
                    (a) => a.productType === ProductType.Notifications
                );
            }

            return {
                orgId: orgIdOrName,
                orgService,
                user,
                organization: {
                    ...orgDto?.organization,
                    hasAccess: orgDto?.hasAccess,
                    isOrgOwner: orgDto?.isOrgOwner
                }
            };
        });
    }
};

export const reload = () => init();

init();
