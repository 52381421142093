import React, { useCallback, useEffect, useState } from "react";
import FormBuilder from "@civicplus/preamble-ui/lib/FormBuilder";
import FormBuilderHelper from "../../../util/FormBuilder";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import SnackBar from "@civicplus/preamble-ui/lib/SnackBar";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import Toggle from "@civicplus/preamble-ui/lib/Toggle";
import { specialCharactersValidation } from "@civicplus/preamble-ui/lib/Validations";
import { Link as RouterLink } from "react-router-dom";
import { OrganizationSettings } from "../../../entities/OrganizationSettings";
import { bottle } from "../../../provider/Bottle";
import { OrganizationSettingsService } from "../../../services/OrganizationSettingsService";
import { emailValidation } from "../../../util/StringHelper";
import { OrganizationService } from "../../../services/OrganizationService";
import { UserManager } from "../../../services/UserManager";
import { usePrompt } from "../../../util/DirtyPrompt";
import { ValidationFunction } from "@civicplus/preamble-ui/lib/Validations/validationProps";

const OrganizationSettingsView: React.FC = () => {
    const organizationSettingsService: OrganizationSettingsService = bottle.container.OrganizationSettingsService;
    const orgService: OrganizationService = bottle.container.OrganizationService;
    const userManager = bottle.container.UserManager as UserManager;

    const [form, setForm] = useState<OrganizationSettings>({
        id: "",
        defaultListSendTime: new Date(),
        defaultListSendTimeZone: "",
        defaultListSendTimeZoneLabel: "",
        defaultListSenderName: "",
        defaultListReplyToEmailAddress: "",
        isSMSEnabled: false
    });
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dirty, setDirty] = useState<boolean>(false);
    const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
    const [toggleIsSMSEnabled, setToggleIsSMSEnabled] = useState<boolean>(false);
    const [buttonContainer, setButtonContainer] = useState<any>(null);
    const [error, setError] = useState<string | null>("");

    const buttonContainerRef = useCallback((node: HTMLElement | null) => {
        if (node !== null) {
            setButtonContainer(node);
        }
    }, []);

    const load = useCallback(async () => {
        const settings = await organizationSettingsService.getOrganizationSettings();
        const isSuperUser = await userManager.isSuperUser();

        if (settings === null) {
            setShowAlert(true);
            setError("Organization was not found");

            return;
        }

        setForm(settings);
        setIsLoading(false);
        setIsSuperUser(isSuperUser);
        setToggleIsSMSEnabled(settings.isSMSEnabled);
    }, [organizationSettingsService, userManager]);

    const deconstructData = (event: any, data: any) => {
        data["defaultListSendTime"] = new Date(data["defaultListSendTime"]);
        data["defaultListSendTimeZone"] = form["defaultListSendTimeZone"];
        data["isSMSEnabled"] = toggleIsSMSEnabled;

        setForm(FormBuilderHelper.deconstructData(form, data));
    };

    const onSave = async (event: any, data: any) => {
        deconstructData(event, data);

        try {
            setSaving(true);

            await organizationSettingsService.updateOrganizationSettings(data);
            setDirty(false);
        } catch (ex: any) {
            setSaving(false);
            return { error: ex.error, skipToastNotification: true };
        }
    };

    const onComplete = (result: any) => {
        if (result && result.error) {
            return;
        }
    };

    useEffect(() => {
        async function init() {
            await load();
        }
        init();
    }, [load]);

    const onDirtyState = (value: boolean) => {
        setDirty(value);
    };

    const build = () => {
        const makeField = (
            name: string,
            label: string,
            editor: string,
            placeholder: string,
            fieldType: string,
            options: { helperText?: string; maxLength?: number },
            isRequired?: boolean
        ) => {
            return {
                name,
                partitioning: "invariant",
                value: form ? form[name] : "",
                properties: {
                    id: "organizationSettings" + name.charAt(0).toUpperCase() + name.slice(1),
                    disabled: saving,
                    isRequired: isRequired ?? true,
                    isListField: false,
                    fieldType,
                    label,
                    placeholder,
                    editor,
                    hints: options.helperText,
                    maxLength: options.maxLength
                }
            };
        };

        const fields = [
            makeField("defaultListSenderName", "Sender Name", "Input", "Sender Name", "String", { maxLength: 50 }),
            makeField(
                "defaultListReplyToEmailAddress",
                "Reply-To Email Address",
                "Input",
                "Reply-To Email Address",
                "String",
                {
                    helperText:
                        "This email address will be used when the user chooses to reply to an email notification.",
                    maxLength: 100
                }
            ),
            makeField("defaultListSendTime", "Send Time", "Time", "Sender Time", "Time", {
                helperText: "This is the time notifications will be sent for items with a scheduled publish date.",
                maxLength: 100
            })
        ];

        const formBuilderProps = {
            ...FormBuilderHelper.makeFormBuilderProps({
                id: "orgSettingsFormBuilder",
                fields,
                data: FormBuilderHelper.constructData(form),
                onSave: onSave,
                onComplete: onComplete,
                onDirtyState: onDirtyState
            }),
            fields: fields,
            hideTagsField: true,
            customValidations: {
                defaultListSenderName: [specialCharactersValidation] as ValidationFunction[],
                defaultListReplyToEmailAddress: [emailValidation] as ValidationFunction[]
            }
        };

        return (
            <>
                <Titlebar
                    id="titlebar"
                    title="Defaults"
                    breadcrumbs={[
                        <Link
                            key="admin"
                            id="admin"
                            //@ts-ignore
                            to={`/${orgService.apiService._orgId}/admin`}
                            component={RouterLink}
                        >
                            Administration
                        </Link>
                    ]}
                    buttonRef={buttonContainerRef}
                />

                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {isSuperUser && (
                            <div style={{ marginTop: 15 }}>
                                <Toggle
                                    label="Enabled SMS"
                                    checked={toggleIsSMSEnabled}
                                    onChange={() => {
                                        setToggleIsSMSEnabled(!toggleIsSMSEnabled);
                                        setDirty(true);
                                    }}
                                />
                                <p style={{ fontSize: "0.75rem", color: "rgba(0,0,0,.6)", margin: "3 0 0" }}>
                                    Enable to turn on SMS messages for this organization
                                </p>
                            </div>
                        )}

                        <FormBuilder
                            {...formBuilderProps}
                            buttonContainer={buttonContainer}
                            onDirtyState={onDirtyState}
                        />
                    </>
                )}
            </>
        );
    };

    usePrompt("Are you sure you want to discard changes?", dirty);

    return (
        <>
            {build()}

            {error && (
                <SnackBar
                    id="createOrganizationSettingsSnackbar"
                    open={showAlert}
                    message={{ message: error }}
                    anchorOrigin={{ horizontal: "center", vertical: "top" }}
                    onClose={() => {
                        setShowAlert(false);
                        setError("");
                    }}
                />
            )}
        </>
    );
};

export default OrganizationSettingsView;
