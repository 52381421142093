/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, useEffect, useState } from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { bottle, setupOrganizationService } from "./provider/Bottle";
import { DirtyStateManager } from "./DirtyStateManager";
import { OrganizationService } from "./services/OrganizationService";
import { UserManager } from "./services/UserManager";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Page from "@civicplus/preamble-ui/lib/Page";
import { HttpHelper } from "@civicplus/preamble-ui/lib/Utilities/HttpHelper";
import RestrictedRoute from "./components/Authentication/RestrictedRoute";
import SignIn from "./components/Authentication/SignIn";
import SignInCallback from "./components/Authentication/SignInCallback";
import SignOut from "./components/Authentication/SignOut";
import Page403 from "./components/Forbidden/Page403";
import NoMatch from "./components/NoMatch/NoMatch";
import { ErrorBoundary } from "./ErrorBoundary";
import HistoryLog from "./views/admin/historyLog/HistoryLog";
import { PendingMessagesList } from "./views/admin/pendingMessage/PendingMessagesList";
import MessageHistoryDetail from "./views/admin/messageHistory/MessageHistoryDetail";
import ListMessageHistory from "./views/admin/messageHistory/ListMessageHistory";
import { Permissions } from "./views/admin/permissions/Permissions";
import OrganizationSettingsView from "./views/admin/settings/OrganizationSettings";
import Subscribers from "./views/admin/subscribers/Subscribers";
import SubscriptionListView from "./views/admin/subscriptionList/SubscriptionListView";
import ViewSubscriptionLists from "./views/admin/subscriptionList/ViewSubscriptionLists";
import Template from "./views/admin/templating/Template";
import PageWrapper from "./views/base/PageWrapper";
import ManageSubscriptions from "./views/client/subscriptions/ManageSubscriptions";
import { Dashboard } from "./views/admin/dashboard/Dashboard";
import Home from "./views/Home";
import { Privacy } from "./views/admin/privacy/Privacy";
import { SubscriberPage } from "./views/admin/subscriber/Subscriber";
import { Unsubscribe } from "./views/client/subscriptions/Unsubscribe";
import Fade from "@material-ui/core/Fade";
import GlobalMessageHistory from "./views/admin/messageHistory/GlobalMessageHistory";
import { SnackbarProvider } from "notistack";
import { UserActivity } from "./views/admin/userActivity/UserActivity";

export const RouteTransitionProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <Fade in={true}>
            <div
                className="prmbl-transition-provider"
                style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
            >
                {children}
            </div>
        </Fade>
    );
};

const App: React.FC = () => {
    const dirtyManager: DirtyStateManager = new DirtyStateManager();
    dirtyManager.register();
    const userManager: UserManager = bottle.container.UserManager;

    const [render, setRender] = useState<boolean>(false);
    const [orgId, setOrgId] = useState<string | null>(null);
    const [branding, setBranding] = useState<any>(null);

    const onDirtyState = (value: boolean) => {
        dirtyManager.onDirty(value);
    };

    useEffect(() => {
        const ignorePaths: string[] = [
            "/civicplusplatform/signincallback",
            "/civicplusplatform/signoutcallback",
            "/?pendo-designer=",
            "/403",
            "/404",
            "/unsubscribe",
            "/admin/user/activity"
        ];

        async function init() {
            await userManager.initAsync();

            let orgId = HttpHelper.getOrgFromUrl(undefined, ignorePaths);
            const localStoreOrg = locallyStoredOrg();
            if (localStoreOrg && !orgId) {
                orgId = localStoreOrg;
            }

            await setupOrganizationService(orgId);

            if ((await userManager.isAuthenticatedAsync(orgId)) && orgId) {
                await bottle.container.OrganizationSettingsService.getOrganizationSettings();
            }

            const orgService: OrganizationService = bottle.container.OrganizationService;
            const branding = orgId ? await orgService.getBranding() : null;

            setRender(true);
            setBranding(branding);
            setOrgId(orgId);
        }
        init();
    }, [userManager]);

    useEffect(() => {
        dirtyManager.dispose();
    }, [dirtyManager]);

    const locallyStoredOrg = () => {
        const org = localStorage.getItem("organization:Notifications");
        const orgSingleString = org && org?.replace(/['"]+/g, "");
        return orgSingleString;
    };

    const buildCitizenOrgSettings = () => {
        if (!branding) {
            return null;
        }
        return {
            name: orgId || "",
            id: orgId || "",
            settings: {
                brandingSettings: branding
            },
            user: {
                profile: {
                    role: [""]
                },
                id_token: "id_token"
            },
            defaultListReplyToEmailAddress: "",
            defaultListSenderName: "",
            defaultListSendTime: new Date(),
            defaultListSendTimeZone: "",
            defaultListSendTimeZoneLabel: "",
            isSMSEnabled: true
        };
    };

    return render ? (
        <SnackbarProvider>
            <ErrorBoundary>
                <Routes>
                    <Route path="/CivicPlusPlatform/SignIn" element={<SignIn fromRoute={window.location.pathname} />} />

                    <Route path="/CivicPlusPlatform/SignInCallback" element={<SignInCallback />} />

                    <Route
                        path="/CivicPlusPlatform/SignOutCallback"
                        element={
                            <RouteTransitionProvider>
                                <SignOut />
                            </RouteTransitionProvider>
                        }
                    />

                    <Route path="/unsubscribe/:hash" element={<Unsubscribe />} />

                    <Route
                        path="/:orgId/admin/*"
                        element={
                            <PageWrapper isCitizenView={false} currentOrgSettings={buildCitizenOrgSettings()!}>
                                <Outlet />
                            </PageWrapper>
                        }
                    >
                        <Route
                            path="*"
                            element={
                                <RestrictedRoute>
                                    <RouteTransitionProvider>
                                        <Dashboard />
                                    </RouteTransitionProvider>
                                </RestrictedRoute>
                            }
                        />

                        <Route
                            path="lists"
                            element={
                                <RestrictedRoute>
                                    <RouteTransitionProvider>
                                        <Outlet />
                                    </RouteTransitionProvider>
                                </RestrictedRoute>
                            }
                        >
                            <Route path="" element={<ViewSubscriptionLists />} />

                            <Route
                                path="create"
                                element={<SubscriptionListView isEditingProp={false} changeState={onDirtyState} />}
                            />

                            <Route path=":id" element={<Outlet />}>
                                <Route
                                    path="edit"
                                    element={<SubscriptionListView isEditingProp={true} changeState={onDirtyState} />}
                                />

                                <Route
                                    path="messages/pending"
                                    element={
                                        <RestrictedRoute>
                                            <RouteTransitionProvider>
                                                <PendingMessagesList />
                                            </RouteTransitionProvider>
                                        </RestrictedRoute>
                                    }
                                />

                                <Route
                                    path="messages/pending/view"
                                    element={
                                        <RestrictedRoute>
                                            <RouteTransitionProvider>
                                                <MessageHistoryDetail isPendingMessage={true} />
                                            </RouteTransitionProvider>
                                        </RestrictedRoute>
                                    }
                                />

                                <Route
                                    path="messages/history"
                                    element={
                                        <RestrictedRoute>
                                            <RouteTransitionProvider>
                                                <ListMessageHistory />
                                            </RouteTransitionProvider>
                                        </RestrictedRoute>
                                    }
                                />

                                <Route
                                    path="messages/history/:messageId"
                                    element={
                                        <RestrictedRoute>
                                            <RouteTransitionProvider>
                                                <MessageHistoryDetail />
                                            </RouteTransitionProvider>
                                        </RestrictedRoute>
                                    }
                                />

                                <Route
                                    path="subscribers"
                                    element={
                                        <RestrictedRoute>
                                            <RouteTransitionProvider>
                                                <Subscribers />
                                            </RouteTransitionProvider>
                                        </RestrictedRoute>
                                    }
                                />

                                <Route
                                    path="subscribers/:importId"
                                    element={
                                        <RestrictedRoute>
                                            <RouteTransitionProvider>
                                                <Subscribers />
                                            </RouteTransitionProvider>
                                        </RestrictedRoute>
                                    }
                                />

                                <Route
                                    path="subscriber/:subscriberId"
                                    element={
                                        <RestrictedRoute>
                                            <RouteTransitionProvider>
                                                <SubscriberPage isGlobalPending={false} />
                                            </RouteTransitionProvider>
                                        </RestrictedRoute>
                                    }
                                />
                            </Route>
                        </Route>

                        <Route path="messages/pending" element={<Outlet />}>
                            <Route path="" element={<PendingMessagesList isGlobalPending={true} />} />
                            <Route path=":id/:messageId" element={<MessageHistoryDetail isPendingMessage={true} />} />
                        </Route>

                        <Route
                            path="settings"
                            element={
                                <RestrictedRoute>
                                    <RouteTransitionProvider>
                                        <OrganizationSettingsView />
                                    </RouteTransitionProvider>
                                </RestrictedRoute>
                            }
                        />

                        <Route
                            path="messages/history"
                            element={
                                <RestrictedRoute>
                                    <RouteTransitionProvider>
                                        <GlobalMessageHistory />
                                    </RouteTransitionProvider>
                                </RestrictedRoute>
                            }
                        />

                        <Route
                            path="messages/history/:messageId"
                            element={
                                <RestrictedRoute>
                                    <RouteTransitionProvider>
                                        <MessageHistoryDetail />
                                    </RouteTransitionProvider>
                                </RestrictedRoute>
                            }
                        />

                        <Route
                            path="subscribers"
                            element={
                                <RestrictedRoute>
                                    <RouteTransitionProvider>
                                        <Subscribers />
                                    </RouteTransitionProvider>
                                </RestrictedRoute>
                            }
                        />

                        <Route
                            path="subscribers/:subscriberId"
                            element={
                                <RestrictedRoute>
                                    <RouteTransitionProvider>
                                        <SubscriberPage isGlobalPending={true} />
                                    </RouteTransitionProvider>
                                </RestrictedRoute>
                            }
                        />

                        <Route
                            path="permissions"
                            element={
                                <RestrictedRoute>
                                    <RouteTransitionProvider>
                                        <Permissions />
                                    </RouteTransitionProvider>
                                </RestrictedRoute>
                            }
                        />

                        <Route
                            path="templates"
                            element={
                                <RestrictedRoute>
                                    <RouteTransitionProvider>
                                        <Template changeState={onDirtyState} />
                                    </RouteTransitionProvider>
                                </RestrictedRoute>
                            }
                        />

                        <Route
                            path="history"
                            element={
                                <RestrictedRoute>
                                    <RouteTransitionProvider>
                                        <HistoryLog />
                                    </RouteTransitionProvider>
                                </RestrictedRoute>
                            }
                        />

                        <Route
                            element={
                                <RouteTransitionProvider>
                                    <NoMatch />
                                </RouteTransitionProvider>
                            }
                        />
                    </Route>

                    <Route
                        path="*"
                        element={
                            <PageWrapper isCitizenView={true} currentOrgSettings={buildCitizenOrgSettings()!}>
                                <Outlet />
                            </PageWrapper>
                        }
                    >
                        <Route
                            path="admin/user/activity"
                            element={
                                <RestrictedRoute>
                                    <RouteTransitionProvider>
                                        <UserActivity />
                                    </RouteTransitionProvider>
                                </RestrictedRoute>
                            }
                        />

                        <Route path=":orgId/*" element={<Outlet />}>
                            <Route
                                path="lists"
                                element={
                                    <RouteTransitionProvider>
                                        <ManageSubscriptions />
                                    </RouteTransitionProvider>
                                }
                            />
                            <Route
                                path="privacy"
                                element={
                                    <RouteTransitionProvider>
                                        <Privacy />
                                    </RouteTransitionProvider>
                                }
                            />
                            <Route path="*" element={<Navigate to="lists" />} />
                        </Route>

                        <Route
                            path="403"
                            element={
                                <RouteTransitionProvider>
                                    <Page403 />
                                </RouteTransitionProvider>
                            }
                        />

                        <Route
                            path="404"
                            element={
                                <RouteTransitionProvider>
                                    <NoMatch />
                                </RouteTransitionProvider>
                            }
                        />

                        <Route
                            path="*"
                            element={
                                orgId ? (
                                    <Navigate to={`/${orgId}/lists`} />
                                ) : (
                                    <RouteTransitionProvider>
                                        <Home />
                                    </RouteTransitionProvider>
                                )
                            }
                        />

                        <Route
                            element={
                                <RouteTransitionProvider>
                                    <NoMatch />
                                </RouteTransitionProvider>
                            }
                        />
                    </Route>
                </Routes>
            </ErrorBoundary>
        </SnackbarProvider>
    ) : (
        <Page id="loading" disableTopbar={true} disableSidebar={true}>
            <Loader verticallyCenter={true} />
        </Page>
    );
};

export default App;
