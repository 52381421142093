import { pendoInitialize } from "@civicplus/preamble-ui/lib/ThirdParty/Pendo";
import { UserManager as OidcUserManager, UserManagerSettings } from "oidc-client-ts";
import { AuthService } from "./AuthService";
import { WindowRouter } from "../shell/WindowRouter";

export class UserManager {
    private _manager: any;
    private _organizationServiceBaseUrl = "";
    private _identityServerBaseUrl = "";
    private _postLogoutRedirectUri = "";
    private _pendoApiKey = "";
    private _authority = "";

    get manager(): any {
        return this._manager;
    }

    constructor(
        private readonly authService: AuthService,
        private readonly windowRouter: WindowRouter
    ) {}

    public isInitialized() {
        return this._manager != null;
    }

    initAsync = async () => {
        if (!this._manager) {
            const data = await this.authService.getConfig();

            const config: UserManagerSettings = {
                authority: `${data.authority}`,
                client_id: `${data.client}`,
                redirect_uri: `${data.postLoginRedirectUri}`,
                response_type: `${data.responseType}`,
                scope: `${data.scope}`,
                post_logout_redirect_uri: `${data.postLogoutRedirectUri}`,
                automaticSilentRenew: true,
                filterProtocolClaims: true,
                loadUserInfo: true,
                response_mode: "query"
            };

            this._organizationServiceBaseUrl = data.organizationServiceUrl;
            this._identityServerBaseUrl = data.identityServerBaseUrl;
            this._postLogoutRedirectUri = data.postLogoutRedirectUri;
            this._authority = data.authority;

            this._manager = new OidcUserManager(config);

            this._manager.events.addUserSignedOut(() => {
                this._manager.removeUser();
            });

            this._pendoApiKey = data.pendoApiKey;
        }
    };

    signInRedirectCallbackAsync = async () => {
        await this._manager.signinRedirectCallback();

        let redirect = "/404";

        if (await this.isAuthenticatedAsync()) {
            redirect = window.sessionStorage.getItem("redirect_uri") || "/";
            await this.handlePendoAsync();
        }
        this.windowRouter.assign(redirect);
    };

    signIn = async (from: string) => {
        window.sessionStorage.setItem("redirect_uri", from);
        return await this._manager.signinRedirect();
    };

    signOut = async () => {
        return await this._manager.signoutRedirect();
    };

    getUserAsync = async () => {
        const user = await this._manager.getUser();
        return user || { expired: true };
    };

    isAuthenticatedAsync = async (orgId?: string | null) => {
        const user = await this.getUserAsync();

        const authenticated = user && !user.expired;
        if (authenticated) {
            await this.handlePendoAsync(user, orgId);
        }

        return authenticated;
    };

    isSuperUser = async (): Promise<boolean> => {
        const user = await this.getUserAsync();
        return user.profile?.role?.includes("notifications_application_superuser");
    };

    handlePendoAsync = async (user: any | undefined = undefined, orgId: string | null = null) => {
        const u = user || (await this.getUserAsync());
        const account = {
            id: "Notifications", // need Org Friendly Name
            cpp_organization_id: orgId ?? undefined
        };

        try {
            pendoInitialize(this._pendoApiKey, u, account);
        } catch (error) {
            // Do nothing right now
        }
    };

    getOrgServiceBaseUrl = () => {
        return this._organizationServiceBaseUrl;
    };

    getIdentityServerBaseUrl = () => this._identityServerBaseUrl;

    getPostLogoutRedirectUrl = () => this._postLogoutRedirectUri;

    getAuthority = () => this._authority;

    getSignoutUrl = async (orgId: string): Promise<string> => {
        const user = await this.getUserAsync();
        return `${this.getAuthority()}/connect/endsession?post_logout_redirect_uri=${this.getPostLogoutRedirectUrl()}&id_token_hint=${
            user.id_token
        }&state=${orgId}`;
    };

    getResetPasswordUrl = (): string => {
        return `${this.getIdentityServerBaseUrl()}/Identity/Account/Manage/ChangePassword`;
    };

    getSettingsUrl = (): string => {
        return `${this.getIdentityServerBaseUrl()}/Identity/Account/Manage`;
    };
}
