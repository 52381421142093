/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";

import Grid from "@civicplus/preamble-ui/lib/Grid";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { getUsersName } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";

import { MessageHistory } from "../../../../entities/Message";
import { fromField, renderListSection } from "./shared";

interface MessageHistoryEmailDetailsTabProps {
    messageHistory: MessageHistory;
    orgId: any;
}

const MessageHistoryEmailDetailsTab: React.FC<MessageHistoryEmailDetailsTabProps> = ({ messageHistory, orgId }) => {
    let inner = <></>;

    if (messageHistory.emailContent !== null && messageHistory.emailContent !== undefined) {
        inner = (
            <div
                id="email-content"
                dangerouslySetInnerHTML={{ __html: messageHistory.emailContent }}
                style={{ whiteSpace: "nowrap" }}
            />
        );
    }

    return (
        <>
            <Grid item={true} xs={12}>
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={3} md={2}>
                        <Typography paragraph={true}>Sender Name:</Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={9} md={10}>
                        <Typography color="textSecondary">{getUsersName(messageHistory.sender!)}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item={true} xs={12}>
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={3} md={2}>
                        <Typography paragraph={true}>Subject:</Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={9} md={10}>
                        <Typography color="textSecondary" data-testid="subjectValue">
                            {messageHistory.subject}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            {fromField(messageHistory)}

            <Grid item={true} xs={12}>
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={3} md={2}>
                        <Typography paragraph={true}>Lists:</Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={9} md={10}>
                        <Typography color="textSecondary">{renderListSection(orgId, messageHistory.lists)}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item={true} xs={12}>
                <div style={{ border: "1px solid #E0E0E0", padding: "1rem" }}>
                    <Typography>{inner}</Typography>
                </div>
            </Grid>
        </>
    );
};

export default MessageHistoryEmailDetailsTab;
