/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-key */
import React, { useCallback, useEffect, useState } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@civicplus/preamble-ui/lib/Table";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import Toggle from "@civicplus/preamble-ui/lib/Toggle";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { Permission } from "../../../entities/Permission";
import { bottle } from "../../../provider/Bottle";
import { PermissionsService } from "../../../services/PermissionsService";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { OrganizationService } from "../../../services/OrganizationService";
import { usePreviousErrors } from "../../../util/PreviousErrors";

export const Permissions: React.FC = () => {
    const userManager = bottle.container.UserManager;
    const permissionsService: PermissionsService = bottle.container.PermissionsService;
    const organizationService: OrganizationService = bottle.container.OrganizationService;
    const currentOrgService = bottle.container.OrgService;

    const [loading, setLoading] = useState<boolean>(true);
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [count, setCount] = useState<number>(0);
    const [areGroupsSyncing, setAreGroupsSyncing] = useState<boolean>(false);
    const [error, setError] = useState<string | null>("");
    const [scrollToTop, setScrollToTop] = useState<boolean>(false);
    const prevError = usePreviousErrors(error);
    const { enqueueSnackbar } = useSnackbar();

    const load = useCallback(
        async (scrollToTop = false): Promise<void> => {
            try {
                const permissions = await permissionsService.getPermissions(page, rowsPerPage);

                setPermissions(permissions.items);
                setCount(permissions.count);
                setLoading(false);
                setScrollToTop(scrollToTop);
            } catch (e: any) {
                setError(e.error);
                setLoading(false);
            }
        },
        [page, permissionsService, rowsPerPage]
    );

    useEffect(() => {
        async function init() {
            await load();
        }
        init();
    }, [load]);

    useEffect(() => {
        if (!loading && scrollToTop) {
            window.scroll(0, 0);
        }
    }, [permissions, count, loading, scrollToTop]);

    const changePage = (page: number) => {
        setPage(page);
        setLoading(true);
    };

    useEffect(() => {
        loading && load(true);
    }, [page, loading, load]);

    const changeRowsPerPage = (rows: number) => {
        setRowsPerPage(rows);
        setPage(0);
        setLoading(true);
    };

    useEffect(() => {
        if (loading && page === 0) {
            load(true);
        }
    }, [load, loading, page, rowsPerPage]);

    const getPermission = (groupId: string): Permission | undefined => {
        const permission = permissions.find((p) => p.groupId === groupId);
        return permission;
    };

    const syncGroups = async () => {
        try {
            setAreGroupsSyncing(true);
            await permissionsService.syncGroups();
            load();
        } catch (e: any) {
            setError(e.error);
            setLoading(false);
        } finally {
            setAreGroupsSyncing(false);
        }
    };

    const onChange = (e: any, groupId: string) => {
        const checked = e.target.checked;
        const permission = getPermission(groupId);

        if (permission) {
            permission.isEnabled = checked;

            permissionsService
                .updatePermission(permission)
                .then((p: Permission) => {
                    if (permission) {
                        setPermissions(
                            permissions.map((mappedPermission: Permission) => {
                                return mappedPermission.groupId === groupId
                                    ? { ...mappedPermission, id: p.id, isEnabled: checked }
                                    : mappedPermission;
                            })
                        );
                    }
                    enqueueSnackbar(
                        p.isEnabled
                            ? `The "${p.groupName}" group now has access to Notifications.`
                            : `The "${p.groupName}" group no longer has access to Notifications.`,
                        { variant: "success" }
                    );
                    return;
                })
                .catch((e) => {
                    setError(e.error);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        if (error && error !== prevError) {
            enqueueSnackbar(error, { variant: "error" });
        }
    }, [enqueueSnackbar, error, prevError]);

    const handleRowClick = (event: React.MouseEvent, row: any) => {
        onChange({ target: { checked: !row.isEnabled } }, row.groupId);
    };

    return (
        <>
            <Titlebar
                id="titlebar"
                title="Permissions"
                description="Enable or disable access to Notifications for your organization's groups."
                buttons={[
                    <Button
                        color="primary"
                        endIcon={<OpenInNewIcon fontSize="small" />}
                        onClick={syncGroups}
                        disabled={areGroupsSyncing}
                    >
                        Sync Groups
                    </Button>,
                    <Button
                        openInNewWindow={true}
                        color="primary"
                        endIcon={<OpenInNewIcon fontSize="small" />}
                        href={`${userManager.getOrgServiceBaseUrl()}${currentOrgService.organization?.id}/groups`}
                    >
                        Manage Users and Groups
                    </Button>
                ]}
                breadcrumbs={[
                    <Link
                        key="admin"
                        id="admin"
                        //@ts-ignore
                        to={`/${organizationService.apiService._orgId}/admin`}
                        component={RouterLink}
                    >
                        Administration
                    </Link>
                ]}
            />

            {loading ? (
                <Loader />
            ) : (
                <Table
                    id="permissions-view-table"
                    // @ts-ignore
                    disableSidebar={true}
                    rows={permissions}
                    onRowClick={handleRowClick}
                    columns={[
                        {
                            columnHeader: "Group Name",
                            rowProperty: "groupName",
                            type: (subject: any) => <Typography variant="body1">{subject.value}</Typography>
                        },
                        {
                            columnHeader: "Has Access",
                            align: "right",
                            rowProperty: "groupId",
                            type: (groupId: any) => {
                                const permission = getPermission(groupId.value);
                                return (
                                    <Toggle
                                        id={`toggle${groupId.value}`}
                                        title={`toggle permission for ${groupId.value}`}
                                        value={`toggle_${groupId.value}`}
                                        checked={permission && permission.isEnabled}
                                        onChange={(e: any) => onChange(e, groupId.value)}
                                        stopPropagation={true}
                                        inputProps={{
                                            "aria-label": `toggle permission for ${groupId.value}`
                                        }}
                                    />
                                );
                            }
                        }
                    ]}
                    pagination={{
                        count: count,
                        page: page,
                        rowsPerPage: rowsPerPage,
                        rowsPerPageOptions: [25, 50, 100],
                        onPageChange: (event: any, page: number) => changePage(page),
                        onRowsPerPageChange: (event: any) => changeRowsPerPage(Number(event.target.value))
                    }}
                />
            )}
        </>
    );
};
