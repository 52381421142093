/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link as RouterLinkWithTypes } from "react-router-dom";
import { AdvancedTableWrapper } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import IconCircleCheck from "@civicplus/preamble-ui/lib/Icons/IconCircleCheck";
import Link from "@civicplus/preamble-ui/lib/Link";
import Menu from "@civicplus/preamble-ui/lib/Menu";
import SvgIcon from "@material-ui/core/SvgIcon";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import Tooltip from "@civicplus/preamble-ui/lib/Tooltip";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { TableData, TableState } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";
import { buildCustomSelectPickerFilter, buildDateFilter } from "@civicplus/preamble-ui/lib/Utilities/odataHelper";
import { mdiCellphoneRemove, mdiDatabaseRemove, mdiEmailRemove } from "@mdi/js";
import { ImportSubscribers } from "../../../components/Modals/ImportSubscribers";
import { Subscriber, SubscriptionType, SuppressionType } from "../../../entities/Subscriber";
import { SubscriptionList } from "../../../entities/SubscriptionList";
import { bottle } from "../../../provider/Bottle";
import { OrganizationSettingsService } from "../../../services/OrganizationSettingsService";
import { SubscribersService } from "../../../services/SubscribersService";
import { SubscriptionListsService } from "../../../services/SubscriptionListsService";
import { buildCustomSuppressionTypeFilter } from "../../../util/FilterHelper";
import { Formatters } from "../../../util/Formatters";
import { useSnackbar } from "notistack";
import { usePreviousErrors } from "../../../util/PreviousErrors";
import {
    LastModified,
    makeCustomSelectPicker,
    makeDateRangeFilter
} from "@civicplus/preamble-ui/lib/Utilities/CommonTableComponents";

const RouterLink: any = RouterLinkWithTypes;

const Subscribers: React.FC = () => {
    const subscribersService: SubscribersService = bottle.container.SubscribersService;
    const listService: SubscriptionListsService = bottle.container.SubscriptionListsService;
    const settingsService: OrganizationSettingsService = bottle.container.OrganizationSettingsService;

    const refContainer: any = useRef<typeof AdvancedTableWrapper | null>(null);

    const { id } = useParams<{ id: string }>();
    const importIdProp = useParams<{ importId: string }>().importId;
    const { orgId } = useParams<{ orgId: string }>();
    const location = useLocation();
    const history = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const list = (location.state && (location.state as any).list) || {};

    const [listId, setListId] = useState<number | null>(id == null ? null : parseInt(id));
    const [listName, setListName] = useState<string>(list.name || "");
    const [showImport, setShowImport] = useState<boolean>(Boolean(importIdProp!));
    const [importId, setImportId] = useState<string | undefined>(importIdProp!);
    const [error, setError] = useState<"">("");
    const [didImport, setDidImport] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);
    const [showUnsubscribeModal, setShowUnsubscribeModal] = useState<boolean>(false);
    const [currentSubscriber, setCurrentSubscriber] = useState<Subscriber | null>(null);
    const [currentDataTableState, setCurrentDataTableState] = useState<any>(null);

    const prevError = usePreviousErrors(error);

    const getSubscriberExportFile = async () => {
        const tableRef = refContainer && (refContainer.current as typeof AdvancedTableWrapper);
        tableRef?.setLoading(true);

        try {
            await subscribersService.getSubscribersExport(listId, listName);
        } catch (e: any) {
            setError(e.message);
        }
        tableRef?.setLoading(false);
    };

    const onModalClose = () => {
        const tableRef = refContainer && (refContainer.current as typeof AdvancedTableWrapper);
        if (didImport && tableRef) {
            tableRef.loadRows();
        }

        setDidImport(false);
        setShowImport(false);
        setImportId(undefined);
    };

    const onUploaded = (): void => {
        setDidImport(true);
    };

    const unsubscribeUser = async (subscriber: Subscriber) => {
        const tableRef = refContainer && (refContainer.current as typeof AdvancedTableWrapper);

        if (tableRef) {
            tableRef.setLoading(true);
            await subscribersService.adminUnsubscribe({
                userId: subscriber.id,
                listId: listId!,
                type: SubscriptionType.None
            });
            await tableRef.loadRows();
            tableRef.setLoading(false);
        }
        setShowUnsubscribeModal(false);
    };

    const getSuppresionTypeIcon = (sub: Subscriber) => {
        if (sub.isEmailSuppressed && sub.isSmsSuppressed) {
            return (
                <Grid container={true} alignItems="center">
                    <Tooltip delay="instant" title="Email and SMS suppressed" placement="bottom">
                        <SvgIcon color="inherit">
                            <path d={mdiDatabaseRemove} />
                        </SvgIcon>
                    </Tooltip>
                </Grid>
            );
        } else if (sub.isEmailSuppressed) {
            return (
                <Grid container={true} alignItems="center">
                    <Tooltip delay="instant" title="Email suppressed" placement="bottom">
                        <SvgIcon color="inherit">
                            <path d={mdiEmailRemove} />
                        </SvgIcon>
                    </Tooltip>
                </Grid>
            );
        } else if (sub.isSmsSuppressed) {
            return (
                <Grid container={true} alignItems="center">
                    <Tooltip delay="instant" title="SMS suppressed" placement="bottom">
                        <SvgIcon color="inherit">
                            <path d={mdiCellphoneRemove} />
                        </SvgIcon>
                    </Tooltip>
                </Grid>
            );
        }

        return "";
    };

    const buildActionMenu = (subscriber: Subscriber) => {
        return (
            <Menu
                id={`subscriber-actions-${subscriber.id}`}
                data-testid={`subscriber-actions-${subscriber.id}`}
                type="action"
                title={`actions for ${subscriber.firstName} ${subscriber.lastName}`}
                stopPropagation={true}
                items={[
                    {
                        display: "Unsubscribe",
                        action: async () => {
                            setShowUnsubscribeModal(true);
                            setCurrentSubscriber(subscriber);
                        }
                    }
                ]}
            />
        );
    };

    const buildFilter = (state: TableState) => {
        let filter = [];

        if (state && state.filterList) {
            const dateFilter = buildDateFilter(state.filterList[7], "subscriptionDate", false);
            const typeFilter = buildCustomSelectPickerFilter(state.filterList[5], "type", false);
            const verifiedFilter = buildCustomSelectPickerFilter(state.filterList[11], "isValidated", false);
            const suppressionTypeFilter = buildCustomSuppressionTypeFilter(state.filterList[8]);

            filter = {
                ...dateFilter,
                ...typeFilter,
                ...verifiedFilter,
                ...suppressionTypeFilter
            };
        }

        if (state && state.search) {
            filter["or"] = {
                lastName: {
                    contains: state.search.trim()
                },
                firstName: {
                    contains: state.search.trim()
                },
                email: {
                    contains: state.search.trim()
                },
                phoneNumber: {
                    contains: state.search.trim()
                }
            };
        }

        return filter;
    };

    const getRows = async (state: TableState): Promise<TableData> => {
        let list: SubscriptionList | null = null;
        if (!listName && listId !== null) {
            list = await listService.getSubscriptionListById(listId.toString());

            setListName(list.name);
        }
        const filter = buildFilter(state);

        setCurrentDataTableState(state);

        const results = await subscribersService.getSubscribers(listId, {
            skip: state.page * state.rowsPerPage,
            top: state.rowsPerPage,
            orderBy: state.sortOrder.name || "lastName",
            orderByDirection: state.sortOrder.direction || "asc",
            filter
        });

        const data =
            results &&
            results.items.map((s) => {
                return [
                    s.id,
                    s.firstName,
                    s.lastName,
                    s.email,
                    Formatters.parsePhoneNumberField(s.phoneNumber),
                    Formatters.formatSubscriptionType(s.type),
                    s.subscriptions?.length,
                    <LastModified
                        date={s.subscriptionDate!}
                        organizationTimeZone={settingsService.settings.defaultListSendTimeZoneLabel}
                        key={`subscriptionDate-${s.id}`}
                    />,
                    getSuppresionTypeIcon(s),
                    s.emailSuppressionDate ? (
                        <LastModified
                            date={s.emailSuppressionDate}
                            organizationTimeZone={settingsService.settings.defaultListSendTimeZoneLabel}
                            key={`emailSuppressionDate-${s.id}`}
                        />
                    ) : (
                        ""
                    ),
                    s.smsSuppressionDate ? (
                        <LastModified
                            date={s.smsSuppressionDate!}
                            organizationTimeZone={settingsService.settings.defaultListSendTimeZoneLabel}
                            key={`smsSuppressionDate-${s.id}`}
                        />
                    ) : (
                        ""
                    ),
                    s.isValidated ? <IconCircleCheck /> : "",
                    SuppressionType,
                    listId === null ? "" : buildActionMenu(s)
                ];
            });

        if (results.count > 0) {
            setCount(results.count);
            return { count: results.count, data };
        } else {
            setCount(0);
            return { count: 0, data: [] };
        }
    };

    const onRowClick = (
        data: string[],
        meta: {
            dataIndex: number;
            rowIndex: number;
        },
        event: any
    ) => {
        const subscriberId = data[0];
        if (listId) {
            history(`/${orgId}/admin/lists/${listId}/subscriber/${subscriberId}`, {
                state: {
                    listId: listId,
                    listName: listName
                }
            });
        } else {
            history(`/${orgId}/admin/subscribers/${subscriberId}/`);
        }
    };

    const buildBody = () => {
        const actionMenu = AdvancedTableWrapper.defaultActionMenuColumn();
        if (listId === null) {
            actionMenu.options.display = "excluded";
        }

        const columns = [
            { name: "id", label: "id", options: { display: "excluded" } },
            { name: "firstName", label: "First Name", options: { sort: true } },
            { name: "lastName", label: "Last Name", options: { sort: true } },
            { name: "email", label: "Email Address", options: { sort: true } },
            { name: "phoneNumber", label: "Phone Number", options: { sort: true } },
            {
                name: "type",
                label: "Type",
                options: {
                    ...makeCustomSelectPicker("Type", [
                        { label: "Email", value: "Email" },
                        { label: "SMS", value: "Sms" },
                        { label: "Both", value: "EmailAndSms" }
                    ]),
                    filter: true,
                    sort: true
                }
            },
            {
                name: "listCount",
                label: "Lists",
                options: {
                    filter: false,
                    sort: false,
                    display: "false"
                }
            },
            {
                name: "subscriptionDate",
                label: "Subscribed",
                options: {
                    ...makeDateRangeFilter("Subscribed", settingsService.settings.defaultListSendTimeZoneLabel, true),
                    filter: true,
                    sort: true
                }
            },
            {
                name: "suppressionType",
                label: "Suppressed",
                options: {
                    sort: false,
                    filter: true,
                    ...makeCustomSelectPicker("SuppressionType", [
                        { label: "Email", value: "Email" },
                        { label: "Sms", value: "Sms" },
                        { label: "Both", value: "Both" },
                        { label: "None", value: "None" }
                    ])
                }
            },
            {
                name: "emailSuppressionDate",
                label: "Email Suppressed Date",
                options: {
                    sort: true,
                    display: "false"
                }
            },
            {
                name: "smsSuppressionDate",
                label: "SMS Suppressed Date",
                options: {
                    sort: true,
                    display: "false"
                }
            },
            {
                name: "isValidated",
                label: "Verified",
                options: {
                    ...makeCustomSelectPicker("Verified", [
                        { label: "Verified", value: true },
                        { label: "Unverified", value: false }
                    ]),
                    sort: true,
                    filter: true
                }
            },
            {
                name: "suppressionTypeHiddenColumn",
                label: "Suppression Type",
                options: {
                    display: "excluded"
                }
            },
            actionMenu
        ];

        return (
            <>
                {showImport && (
                    <ImportSubscribers
                        orgId={orgId!}
                        selectedListId={listId!}
                        importId={importId}
                        replaceRoute={(routePath: string) => history(routePath, { replace: true })}
                        onUploaded={onUploaded}
                        onClose={onModalClose}
                    />
                )}

                <Titlebar
                    id="subscribers-titlebar"
                    title={`${listName} Subscribers`}
                    breadcrumbs={[
                        <Link
                            key="list"
                            component={RouterLink}
                            // @ts-ignore
                            to={`/${orgId}/admin/lists/`}
                        >
                            Subscription Lists
                        </Link>
                    ]}
                    buttons={
                        listId
                            ? [
                                  <Button
                                      key="import-button"
                                      data-testid="import-subscribers-btn"
                                      color="primary"
                                      onClick={() => {
                                          setShowImport(true);
                                      }}
                                  >
                                      Import
                                  </Button>
                              ]
                            : undefined
                    }
                />

                <Typography gutterBottom={true}>{Formatters.formatNumber(count)} Subscription(s)</Typography>

                <AdvancedTableWrapper
                    columns={columns}
                    key={listId?.toString()}
                    rows={getRows}
                    scrollToTop={true}
                    ref={refContainer}
                    download={true}
                    onDownload={getSubscriberExportFile}
                    emptyMessage="You currently have no subscribers for this list."
                    showSearch={true}
                    showFilter={true}
                    initialSortDirection="asc"
                    initialSortColumn="lastName"
                    rowsPerPage={25}
                    rowsPerPageOptions={[25, 50, 100]}
                    initialSortColumnIndex={1}
                    closeSnackbar={() => {}}
                    enqueueSnackbar={() => "message"}
                    onRowClick={onRowClick}
                />

                {showUnsubscribeModal && (
                    <Dialog
                        title="Unsubscribe"
                        open={showUnsubscribeModal}
                        onClose={() => {
                            setShowUnsubscribeModal(false);
                        }}
                        actions={[
                            <Button
                                color="primary"
                                onClick={() => unsubscribeUser(currentSubscriber!)}
                                key="ok"
                                id="ok"
                            >
                                Ok
                            </Button>,
                            <Button
                                onClick={() => {
                                    setShowUnsubscribeModal(false);
                                }}
                                key="cancel"
                            >
                                Cancel
                            </Button>
                        ]}
                    >
                        <Typography>Are you sure you want to remove this subscriber?</Typography>
                    </Dialog>
                )}
            </>
        );
    };

    useEffect(() => {
        if (prevError && error && error !== prevError) {
            enqueueSnackbar(error, { variant: "error" });
        }
    }, [error, prevError, enqueueSnackbar]);

    const prevSubscriberId = useRef<string | undefined>();
    useEffect(() => {
        //assign the ref's current value to the params id
        prevSubscriberId.current = id;
    }, [id]);

    useEffect(() => {
        if (prevError && error && error !== prevError) {
            enqueueSnackbar(error, { variant: "error" });
        }

        if (prevSubscriberId && prevSubscriberId.current !== id) {
            const list = (location.state && (location.state as any).list) || {};

            setListId(id == null ? null : parseInt(id));
            setListName(list.name || "");
            setCount(0);
        }
    }, [error, id, location.state, prevError, enqueueSnackbar]);

    return buildBody();
};

export default Subscribers;
