import React, { useState, useEffect } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { useParams } from "react-router-dom";
import { OrgSubscriptions } from "../../../entities/OrgSubscriptions";
import { bottle } from "../../../provider/Bottle";
import { UnsubscribeService } from "../../../services/UnsubscribeService";
import ProductThemeProvider from "@civicplus/preamble-ui/lib/ProductThemeProvider";

export const Unsubscribe: React.FunctionComponent<any> = () => {
    const userHashProp = useParams<{ hash: string }>().hash ?? "";
    const [hash, setHash] = useState<string>("");
    const [orgSubscriptions, setOrgSubscriptions] = useState<OrgSubscriptions>();
    const [confirmMessage, setConfirmMessage] = useState<string>();

    const unsubscribeService: UnsubscribeService = bottle.container.UnsubscribeService;
    const selectedListsMessage = "Thank you. You have been unsubscribed from notifications for the selected lists.";

    useEffect(() => {
        async function initAsync() {
            const subs = await unsubscribeService.getSubscriptions(userHashProp);
            setHash(userHashProp);
            setOrgSubscriptions(subs);
        }

        initAsync();
    }, [userHashProp, unsubscribeService]);

    useEffect(() => {
        if (orgSubscriptions?.subscriptions?.length === 0) {
            setConfirmMessage(selectedListsMessage);
        }
    }, [orgSubscriptions]);

    const unsubscribeFromLists = async () => {
        await unsubscribeService.unsubscribeFromLists(hash);
        setConfirmMessage(selectedListsMessage);
    };

    const unsubscribeFromAll = async () => {
        await unsubscribeService.unsubscribeFromAll(hash);
        setConfirmMessage(
            `Thank you. You have been unsubscribed from all notifications for ${orgSubscriptions?.organization}.`
        );
    };

    return (
        <Grid container={true} spacing={3}>
            {!confirmMessage && (
                <Grid item={true} xs={12}>
                    <Typography variant="h5">
                        You have asked to unsubscribe from the following {orgSubscriptions?.organization} lists:
                    </Typography>

                    <ul>
                        {orgSubscriptions?.subscriptions.map((s, key) => {
                            return (
                                <li>
                                    <Typography key={key} variant="body1">
                                        {s.list.name}
                                    </Typography>
                                </li>
                            );
                        })}
                    </ul>

                    <ProductThemeProvider>
                        <ButtonGroup layout="left">
                            <Button color="primary" onClick={unsubscribeFromLists}>
                                Unsubscribe From Above List(s)
                            </Button>
                            <Button color="primary" onClick={unsubscribeFromAll}>
                                Unsubscribe From All {orgSubscriptions?.organization} Lists
                            </Button>
                        </ButtonGroup>
                    </ProductThemeProvider>
                </Grid>
            )}

            {confirmMessage && (
                <Grid item={true} xs={12}>
                    <Typography variant="h5">{confirmMessage}</Typography>
                </Grid>
            )}
        </Grid>
    );
};
