import React, { useEffect, useState } from "react";
import { Link as RouterLinkWithTypes, useLocation, useParams } from "react-router-dom";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Divider from "@civicplus/preamble-ui/lib/Divider";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import IconCircleCheck from "@civicplus/preamble-ui/lib/Icons/IconCircleCheck";
import ItemMeta from "@civicplus/preamble-ui/lib/ItemMeta";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import PhoneNumberInput from "@civicplus/preamble-ui/lib/PhoneNumberInput";
import StickyRightBar from "@civicplus/preamble-ui/lib/StickyRightBar";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import Tooltip from "@civicplus/preamble-ui/lib/Tooltip";
import { default as MuiLink } from "@material-ui/core/Link";
import { Subscriber, Subscription } from "../../../entities/Subscriber";
import { SubscribersService } from "../../../services/SubscribersService";
import { SubscriptionListsService } from "../../../services/SubscriptionListsService";
import { bottle } from "../../../provider/Bottle";
import { SubscriptionList } from "../../../entities/SubscriptionList";

const RouterLink: any = RouterLinkWithTypes;
const Link: any = MuiLink;

interface SubscriberPageProps {
    overrideBack?: () => void;
    isGlobalPending: boolean;
}

export const SubscriberPage: React.FC<SubscriberPageProps> = ({ overrideBack, isGlobalPending }) => {
    const orgService = bottle.container.OrgService;
    const subscribersService: SubscribersService = bottle.container.SubscribersService;
    const subscriptionListService: SubscriptionListsService = bottle.container.SubscriptionListsService;
    const [subscriber, setSubscriber] = useState({} as undefined | Subscriber);
    const [subscriptionListOptions, setSubscriptionListOptions] = useState([] as OptionShape[]);
    const [subscriptionListsSubscribed, setSubscriptionListsSubscribed] = useState([] as OptionShape[]);
    const location = useLocation();
    const list = location?.state as { listId: number; listName: string };

    const { subscriberId } = useParams();

    useEffect(() => {
        let isCanceled = false;

        async function init() {
            if (subscriberId) {
                const results = await subscribersService.getSubscriberById(subscriberId);
                if (!isCanceled) {
                    setSubscriber(results);
                }
            }
        }

        init();
        return () => {
            isCanceled = true;
        };
    }, [subscriberId, subscribersService]);

    useEffect(() => {
        let isCanceled = false;
        async function getAllLists() {
            const allLists = await subscriptionListService.getSubscriptionLists();

            const nameAndId = allLists.items.map((x: SubscriptionList) => {
                const ac: OptionShape = {
                    label: x.name,
                    value: x.id!
                };
                return ac;
            });
            if (!isCanceled) {
                setSubscriptionListOptions(nameAndId!);
            }
        }
        getAllLists();

        return () => {
            isCanceled = true;
        };
    }, [subscriptionListService]);

    useEffect(() => {
        const listValues =
            subscriber &&
            subscriber.subscriptions &&
            subscriber?.subscriptions!.map((x: Subscription) => {
                const list: OptionShape = {
                    label: x.list!.name,
                    value: x.list!.id!
                };
                return list;
            });

        setSubscriptionListsSubscribed(listValues!);
    }, [subscriber]);

    const goBack = () => {
        if (overrideBack) {
            return overrideBack();
        }
        window.history.back();
    };

    const getBreadcrumbs = () => {
        if (isGlobalPending) {
            return [
                <Link key="list" component={RouterLink} to={`/${orgService.orgId}/admin/subscribers`}>
                    All Subscribers
                </Link>
            ];
        }
        return [
            <Link key="list" component={RouterLink} to={`/${orgService.orgId}/admin`}>
                Subscription Lists
            </Link>,
            <Link
                component={RouterLink}
                key="current-list"
                to={`/${orgService.orgId}/admin/lists/${list?.listId}/edit`}
            >
                {list?.listName}
            </Link>,
            <Link
                key="subscribers"
                component={RouterLink}
                to={`/${orgService.orgId}/admin/lists/${list?.listId}/subscribers/`}
            >
                Subscribers
            </Link>
        ];
    };

    const rightBar = (
        <div style={{ marginTop: "12%", marginLeft: "5%" }}>
            <Grid container={true}>
                <Grid item={true} xs={12}>
                    <ButtonGroup layout="stacked">
                        <Button onClick={goBack} id="subscriber-page-cancel" fullWidth={true}>
                            Back
                        </Button>
                    </ButtonGroup>
                </Grid>

                <Grid item={true} xs={12}>
                    <ItemMeta
                        createdDate={new Date(subscriber!.subscriptionDate!)}
                        createdCustomLabel="First Subscribed"
                    />
                </Grid>

                {subscriber!.emailSuppressionDate ? (
                    <Grid item={true} xs={12}>
                        <Divider id="email-divider-id" />
                        <ItemMeta
                            createdDate={new Date(subscriber!.emailSuppressionDate!)}
                            createdCustomLabel="Email Suppression Date"
                        />
                    </Grid>
                ) : null}

                {subscriber!.smsSuppressionDate ? (
                    <Grid item={true} xs={12}>
                        <Divider id="sms-divider-id" />
                        <ItemMeta
                            createdDate={new Date(subscriber!.smsSuppressionDate!)}
                            createdCustomLabel="SMS Suppression Date"
                        />
                    </Grid>
                ) : null}
            </Grid>
        </div>
    );

    const emailEndAdornment =
        subscriber && subscriber.isValidated ? (
            <Tooltip title="Validated">
                <IconCircleCheck />
            </Tooltip>
        ) : (
            <Tooltip title="Not Validated">
                <ErrorOutlineIcon />
            </Tooltip>
        );

    return !subscriber ? (
        <Loader verticallyCenter={true} />
    ) : (
        <StickyRightBar id="subscriber-view" rightBar={rightBar}>
            <Titlebar title={`${subscriber.firstName} ${subscriber.lastName}`} breadcrumbs={getBreadcrumbs()} />

            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} md={6}>
                    <TextInput
                        disabled={true}
                        label="First Name"
                        value={subscriber.firstName ?? ""}
                        fullWidth={true}
                        inputProps={{ "aria-label": `first name label for ${subscriber.firstName ?? ""}` }}
                    />
                </Grid>

                <Grid item={true} xs={12} md={6}>
                    <TextInput
                        disabled={true}
                        label="Last Name"
                        value={subscriber.lastName ?? ""}
                        fullWidth={true}
                        inputProps={{ "aria-label": `last name label for ${subscriber.lastName ?? ""}` }}
                    />
                </Grid>
            </Grid>

            <TextInput
                disabled={true}
                label="E-Mail"
                value={subscriber.email ?? ""}
                fullWidth={true}
                endAdornment={emailEndAdornment}
                inputProps={{ "aria-label": `email label for ${subscriber.email ?? ""}` }}
            />

            <PhoneNumberInput
                disabled={true}
                label="Phone Number"
                value={subscriber.phoneNumber ?? ""}
                fullWidth={true}
            />

            <Autocomplete
                label="Subscribed To"
                fullWidth={true}
                multiSelect={true}
                disabled={true}
                options={subscriptionListOptions}
                value={subscriptionListsSubscribed}
            />
        </StickyRightBar>
    );
};
