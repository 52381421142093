/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import AdvancedTableWrapper from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Table from "@civicplus/preamble-ui/lib/Table";
import TableBody from "@civicplus/preamble-ui/lib/TableBody";
import TableCell from "@civicplus/preamble-ui/lib/TableCell";
import TableRow from "@civicplus/preamble-ui/lib/TableRow";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { MessageHistory } from "../../../../entities/Message";
import { bottle } from "../../../../provider/Bottle";
import { MessageService } from "../../../../services/MessageService";
import { Formatters } from "../../../../util/Formatters";
import { fromField } from "./shared";
import { format, parseISO } from "date-fns";
import {
    TableColumn,
    TableData,
    TableState
} from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";

interface IMessageHistorySmsActivityTabProps {
    listId: number | null;
    messageId: number;
    messageHistory: MessageHistory;
}

const MessageHistorySmsActivityTab: React.FC<IMessageHistorySmsActivityTabProps> = ({
    listId,
    messageId,
    messageHistory
}) => {
    const messageService: MessageService = bottle.container.MessageService;
    const [messageDetails, setMessageDetails] = useState<any>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const showSmsActivityDialog = async (id: string) => {
        const details = await messageService.getSmsMessageActivityDetails(messageId, id);

        setMessageDetails(details);
        setDialogOpen(true);
    };

    const getSmsActivityDetails = () => {
        let to = "";
        let from = "";

        const rows = messageDetails?.map((item: any) => {
            if (item.to) {
                to = item.to;
            }

            if (item.from) {
                from = item.from;
            }

            return (
                <TableRow id={`${item.from}-${item.time}`} key={`${item.from}-${item.time}`}>
                    <TableCell id={`${item.from}-${item.time}-date`} scope="row">
                        {format(
                            new Date(parseISO(item.time.endsWith("Z") ? item.time : `${item.time}Z`)),
                            "MM/dd/yyyy h:mm aaaa"
                        )}
                    </TableCell>

                    <TableCell id={`${item.from}-${item.time}-event`}>
                        <Typography variant="button">{item.type.replace("sms_", "")}</Typography>
                    </TableCell>
                </TableRow>
            );
        });

        // Show Some info first. Like Email address and message info?
        return (
            <>
                <Grid item={true} xs={12}>
                    <Grid container={true}>
                        <Grid item={true} xs={12} sm={3} md={2}>
                            <Typography paragraph={true}>Text:</Typography>
                        </Grid>

                        <Grid item={true} xs={12} sm={9} md={10}>
                            <Typography color="textSecondary">
                                {messageHistory.smsText || "This notification does not include SMS data."}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {fromField(messageHistory!, Formatters.parsePhoneNumberField(from))}

                <Grid item={true} xs={12}>
                    <Grid container={true}>
                        <Grid item={true} xs={12} sm={3} md={2}>
                            <Typography paragraph={true}>To:</Typography>
                        </Grid>

                        <Grid item={true} xs={12} sm={9} md={10}>
                            <Typography color="textSecondary">{Formatters.parsePhoneNumberField(to)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item={true} xs={12}>
                    <Grid container={true}>
                        <Grid item={true} xs={12}>
                            <Table compact={true} id="activity-details">
                                <TableBody id="activity-details-body">{rows}</TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };

    const getSmsRows = async (state: TableState): Promise<TableData> => {
        const activity = await messageService.getSentMessageActivitySms(
            messageId,
            listId,
            state.page,
            state.rowsPerPage
        );

        const items = activity.items.map((item: any) => {
            return [
                <Typography variant="button">{item.type.replace("sms_", "")}</Typography>,
                Formatters.parsePhoneNumberField(item.to ?? ""),
                format(
                    new Date(parseISO(item.time.endsWith("Z") ? item.time : `${item.time}Z`)),
                    "MM/dd/yyyy h:mm aaaa"
                ),
                item.messageId
            ];
        });

        return { count: activity.count, data: items };
    };

    const columns: TableColumn[] = [
        { name: "event", label: "Event" },
        {
            name: "recipient",
            options: {
                label: "Recipient",
                filter: false
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                sort: true,
                filter: false
            }
        },
        {
            name: "messageId",
            label: "Message Id",
            options: { sort: false, filter: false, display: "false" }
        }
    ];

    if (!messageHistory.smsText) {
        return (
            <Grid item={true} xs={12}>
                <div style={{ border: "1px solid #E0E0E0", padding: "1rem" }}>
                    <Typography>
                        <div id="sms-content">This notification does not include SMS data.</div>
                    </Typography>
                </div>
            </Grid>
        );
    }

    return (
        <>
            <Dialog
                title="Message Log Details"
                fullWidth={true}
                maxWidth="xl"
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setMessageDetails(null);
                }}
                actions={[
                    <Button
                        color="primary"
                        onClick={async () => {
                            setDialogOpen(false);
                            setMessageDetails(null);
                        }}
                        key="ok"
                    >
                        OK
                    </Button>
                ]}
            >
                {messageDetails && getSmsActivityDetails()}
            </Dialog>

            <AdvancedTableWrapper
                columns={columns}
                rows={getSmsRows}
                scrollToTop={true}
                emptyMessage="There is currently no events to view."
                download={false}
                initialSortDirection="desc"
                initialSortColumn="date"
                initialSortColumnIndex={2}
                showFilter={false}
                rowsPerPage={25}
                rowsPerPageOptions={[25, 50, 100]}
                onRowClick={async (rowData: string[], rowMeta: unknown) => {
                    const id = rowData[3];
                    await showSmsActivityDialog(id);
                }}
            />
        </>
    );
};

export default MessageHistorySmsActivityTab;
