/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";

import Grid from "@civicplus/preamble-ui/lib/Grid";
import Typography from "@civicplus/preamble-ui/lib/Typography";

import { MessageHistory } from "../../../../entities/Message";
import { renderListSection } from "./shared";

interface MessageHistorySmsDetailsTabProps {
    messageHistory: MessageHistory;
    orgId: any;
}

const MessageHistorySmsDetailsTab: React.FC<MessageHistorySmsDetailsTabProps> = ({ messageHistory, orgId }) => {
    return (
        <>
            <Grid item={true} xs={12}>
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={3} md={2}>
                        <Typography paragraph={true}>Lists:</Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={9} md={10}>
                        <Typography color="textSecondary">{renderListSection(orgId, messageHistory.lists)}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item={true} xs={12}>
                <div style={{ border: "1px solid #E0E0E0", padding: "1rem" }}>
                    <Typography>
                        <div id="sms-content">
                            {messageHistory.smsText || "This notification does not include SMS data."}
                        </div>
                    </Typography>
                </div>
            </Grid>
        </>
    );
};

export default MessageHistorySmsDetailsTab;
